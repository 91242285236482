import { apiHelper } from '@/helpers/apiHelper'
import API from '@axios'

const API_URL = '/api/reportqueries'

export default {
  ...apiHelper(API_URL),

  getAllColumns: id => API.get(`${API_URL}/${id}/field`),

  getAllFilters: id => API.get(`${API_URL}/${id}/filter`),
}
