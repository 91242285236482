const RootEvents = {
  SET_PAGE_TITLE: 'setPageTitle',
  CLEAR_RELATIONSHIPS: 'clearRelationships',
  CLEAR_CUSTOM_FIELDS: 'clearCustomFields',
  GET_LINKABLE_ENTITIES: 'getLinkableEntities',
  SIGNATURE_REQUEST_SAVED: 'signatureRequestSaved',
  GET_RELATIONSHIPS: 'getRelationships',
  GET_CUSTOM_ENTITIES: 'getCustomEntities',
  UPDATE_BREADCRUMB_ITEM: 'updateBreadcrumbItem',
  IMEX_IMPORT_STATUS_TOGGLED: 'imexImportStatusToggled',
  ADV_SEARCH_RUN: 'advancedSearchRun',
  ADV_SEARCH_CLEAR: 'advancedSearchClear',
  ADV_SEARCH_CONFIRM_SAVE: 'advancedSearchConfirmSave',
  FORMS_ADD_SELECTED_ENTITY: 'formsAddSelectedEntity',
  FORMS_REMOVE_SELECTED_ENTITY: 'formsRemoveSelectedEntity',
  FORMS_ADD_SELECTED_FIELD: 'formsAddSelectedField',
  FORMS_REMOVE_SELECTED_FIELD: 'formsRemoveSelectedField',
  FORMS_SELECT_ALL_FIELD_OPTIONS: 'formsSelectAllFieldOptions',
  FORMS_DESELECT_ALL_FIELD_OPTIONS: 'formsDeselectAllFieldOptions',
  FORMS_TOGGLE_SUBMIT_CONFIG_MODAL: 'formsToggleSubmitConfigModal',
  FORMS_TOGGLE_SHARE_MODAL: 'formsToggleShareModal',
  FORMS_PREFILL_DATA: 'formsPrefillData',
  WIDGET_TOGGLE_FORM: 'widgetToggleForm',
  WIDGET_CLEAR_ALL: 'widgetClearAll',
  WIDGET_INITIATE_DATASOURCE_TABLE: 'widgetInitiateDataSource',
  BASIC_ASSIGN_VALUE: 'basicAssignValue',
  CALL_API: 'callApi',
  DASHBOARD_FILTER_APPLY: 'dashboardFilterApply',
}

export default RootEvents
