<template>
  <b-card
    id="page--reports"
    no-body
  >
    <div class="card-body">
      <div class="pb-1">
        <imex-actions-dropdown
          :hide-actions="true"
          schema-name="reports"
          entity-type="report"
        />
      </div>
      <div
        v-if="items.length === 0"
        class="tw-flex justify-content-center"
      >
        <span> {{ $t('No matching records found') }} </span>
      </div>

      <div
        v-for="item in items"
        :key="item.id"
        class="prozess-company-contact__item"
      >
        <prozess-resource-name
          :id="`report-${item.id}`"
          class="text-nowrap d-inline-block"
          :to="{
            name: 'reports.generate',
            params: { id: item.id },
          }"
          :title="item.label"
          :is-direct-download="item.isDirectDownload"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import reportService from '@/services/reportQueries'
import listMixinFactory from '@/mixins/list'
import ImexActionsDropdown from '@/views/shared/Imex/ImexActionsDropdown.vue'

export default {
  components: {
    ImexActionsDropdown,
  },
  mixins: [
    listMixinFactory({
      routeName: 'reports',
      searchField: 'label',
      service: reportService,

      tableColumns: [{ key: 'label', label: 'Title', class: 'disabled' }],
    }),
  ],
  methods: {
    afterFetchCallback(res) {
      this.items = res.data
    },

    async getAllMethod(query) {
      return reportService.getAll(query)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/company-contact-list.scss';
</style>
